//Breakpoint variables
$breakpoint-sm: 576px; // Small devices (phones)
$breakpoint-md: 768px; // Medium devices (tablets)
$breakpoint-lg: 1200px; // Large devices (desktops)
$breakpoint-xl: 1440px; // Extra-large devices (large desktops)
$breakpoint-xxl: 1920px;

//Primary Colors
$primaryBlack: #000;
$primaryGray:#9d9d9c;
$primaryYellow: #ffd50e;
$primaryWhite: #f6f6f6;
$primaryGreen: #009640;
$primaryGrayATMStroke: #706f6f;
$secondaryGrayATMStroke: #9D9D9C;

//Alerts Colors
$alertRed: #e30613;
$alertGreen: #009640;
