// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


@import './assets/css/variables.scss';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $atm-monitoring-web-app-primary: mat.define-palette(mat.$green-palette);
// $atm-monitoring-web-app-accent: mat.define-palette(mat.$grey-palette, 800);

// // The warn palette is optional (defaults to red).
// $atm-monitoring-web-app-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $atm-monitoring-web-app-theme: mat.define-light-theme((
//   color: (
//     primary: $atm-monitoring-web-app-primary,
//     accent: $atm-monitoring-web-app-accent,
//     warn: $atm-monitoring-web-app-warn,
//   )
// ));

$atm-monitoring-web-app-theme: mat.define-light-theme((
  color: (
    primary: (
      default: $primaryYellow
    ),
    accent: (
      default: $primaryGray
    ),
    warn: (
      default: $alertRed
    ),
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($atm-monitoring-web-app-theme);



html, body { height: 100%; }
body { margin: 0; font-family: "AvenirLTStdBook",Roboto, "Helvetica Neue", sans-serif; background-color: $primaryWhite; }

mat-form-field{
  width: 100%;

  input{
    caret-color: $primaryBlack !important;
  }
}

mat-label{
    color: $primaryBlack;
}

mat-label{
    color: $primaryBlack;
}


@font-face {
  font-family: 'AvenirLTStd';
  src: url('./assets/fonts/AvenirLTStd-Black.otf');
}

@font-face {
  font-family: 'AvenirLTStdBook';
  src: url('./assets/fonts/AvenirLTStd-Book.otf');
}

@font-face {
  font-family: 'AvenirLTStdRoman';
  src: url('./assets/fonts/AvenirLTStd-Roman.otf');
}

.snack-bar-error{

  .mat-mdc-snack-bar-actions{
    button{
      color: $primaryWhite !important;
      background-color: $alertRed;
    }
  }
}

.snack-bar{

  .mat-mdc-snack-bar-actions{
    button{
      color: $primaryBlack !important;
      background-color: $primaryYellow;
    }
  }
}